.banners-swiper {
    overflow: hidden;
    width: 100%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner {
    display: flex;
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 2/1;
}

.banner > img {
    aspect-ratio: 1/1;
}

.banner-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    font-weight: bold;
}

@media(max-width: 600px) {
    .banner {
        flex-direction: column;

    }

    .banner > img {
        width: 100%;
    }

    .banner-text {
        width: 100%;
        font-size: 16pt;
        padding-top: 50px;
        padding-bottom: 30px;
    }
}

@media(min-width: 600px) {
    .banner > img {
        height: 100%;
    }

    .banner-text {
        height: 100%;
        aspect-ratio: 1/1;
        font-size: 28pt;
    }
}

.banner-buttons {
    display: flex;
    padding-left: calc((100vw - 1200px) / 2);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #E3E3E3;
    overflow-x: auto;
    overflow-y: hidden;
}

.banner-buttons::-webkit-scrollbar {
    display: none;
}

.banner-slide-button {
    max-width: 100%;
    white-space: nowrap;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    border-radius: 20px;
    margin-left: 10px;
    opacity: 0.9;
    transition: scale 0.1s;
    @media(hover: hover) {
        &:hover {
            opacity: 1.0;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
}

.banner-slide-button-active {
    color: #e11134;
    text-decoration: underline;
}