.itemView-body {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
}

.itemView-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
}

.itemView-side {
    display: flex;
    width: 350px;
    max-width: 600px;
}

.itemView-images {
    width: 50%;
    display: flex;
    aspect-ratio: 1/1;
    align-items: flex-start;
    height: 100%;
}

.item-swiper {
    overflow: hidden;
}

.img-wrapper {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.item-info-side {
    padding: 10px;
    flex-direction: column;
}

.item-info-section {
    display: flex;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    flex-direction: column;
    margin-top: 10px;
}

.item-view-title {
    font-size: 14pt;
}

.item-price-section {
    display: flex;
    justify-content: space-between;
}

.item-price-section {
    padding-top: 10px;
}

.section-info-title {
    font-weight: bold;
    padding-bottom: 5px;
}

.spec-record {
    display: flex;
    flex-direction: column;
    font-size: 12pt;
    padding-top: 10px;
}

.spec-record-title {
    opacity: 0.5;
}

.spec-record-value {

}

.call-us-text {
    padding-bottom: 10px;
}

.item-swiper {
    overflow: hidden;
    aspect-ratio: 1/1;
    width: 100%;
    @media(min-width: 850px) {
        top: 0px;
        position: sticky;
    }
}

.swiper-button-prev::after {
    display: none;
}

.swiper-button-next::after {
    display: none;
}

.cart-popup {
    width: 250px;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.popup-button {
    padding-top: 10px;
}

@media(max-width: 800px) {
    .itemView-content {
        flex-direction: column;
        justify-content: center;
    }

    .itemView-side { 
        width: 100%;
        max-width: 100%;
    }
}

.item-call-button {
    width: 100%;
    text-decoration: none;
    background-color: yellow
}

.delivery-option {
    font-weight: bold;
    margin-top: 10px;
}

.delivery-option-value {
    margin-top: 5px;
}