.footer-body {
    display: flex;
    width: 100%;
    background-color: #423C4A;
    justify-content: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    @media(max-width: 800px) {
        padding-bottom: 40px;
    }
}

.header-logo {
    display: flex;
    font-family: "Audiowide";
    color: white;
    font-size: 22pt;
}

.header-logo-capital {
    color: #e11134;
    font-family: "Audiowide";
    text-decoration: none;
}

.footer-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}

.footer-section {
    width: 25%;
    height: 400px;
    padding: 10px;
    color: white;
    padding-top: 20px;
}

.footer-section-upper {
    @media(max-width: 800px) {
        height: 300px;
    }
}

.footer-section-title {
    font-size: 14pt;
}

.footer-title-divider {
    height: 5px;
    width: 40px;
    background-color: red;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-footer-section {
    opacity: 0.5;
    margin-top: 10px;
    font-size: 11pt;
}

.footer-section-contact {
    padding-bottom: 10px;
}

.footer-contact-space {
    margin-left: 5px;
}

@media(max-width: 800px) {
    .footer-section {
        height: inherit;
        width: 100%;
    }

    .footer-body {
        padding-top: 20px;
    }
}

.info-link {
    color: inherit;
    text-decoration: none;
}