.info-body {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.info-content {
    width: 100%;
    max-width: 1100px;
    padding: 10px;
}

.info-title {
    width: 100%;
    text-align: center;
    font-size: 24pt;
    padding-top: 40px;
    padding-bottom: 40px;
}