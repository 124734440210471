* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: "Roboto";
}

.app {
    display: flex;
    flex-direction: column;
}

span {
    width: 10px;
    height: 10px;
}