.order-view-body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.order-view-content {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    @media(max-width: 600px) {
        width: 100%;
    }
}

input {
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    padding: 7px 12px;
    border-radius: 15px;
    width: 100%;
    font-size: 12pt;
    margin-top: 10px;
}

.delivery-options {
    display: flex;
    width: 100%;
    padding-top: 10px;
}

.where-pickup {
    margin-top: 10px;
}

.pickup-time {
    margin-top: 0px;
    opacity: 0.5;
}

.mixed-input {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.mixed-field-left {
    width: calc(100% - 10px);
    margin-left: 10px;
    margin-top: 0px;
}

.delivery-method {
    margin-top: 10px;
    opacity: 0.5;
}