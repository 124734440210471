.navbar-body {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #423C4A;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 999999;
    @media(min-width: 600px) {
        display: none;
    }
}

.navbar-content {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-around;
    align-items: center;
}

.navbar-corners {
    width: 100%;
    position: fixed;
    bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media(min-width: 600px) {
        display: none;
    }
}

.navbar-corner-left {
    width: 50px;
    height: 50px;
    border-bottom-left-radius: 25px;
    box-shadow: 0px 25px 0px 0px #423C4A;
}

.navbar-corner-right {
    width: 50px;
    height: 50px;
    border-bottom-right-radius: 25px;
    box-shadow: 0px 25px 0px 0px #423C4A;
}

.navbar-button {
    display: flex;
    color: white;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    font-size: 10pt;
    width: 100px;
    text-decoration: none;
}

.navbar-button-label {
    padding-top: 1px;
}

.navbar-menu-button {
    background-color: #e11134;
    width: 40px;
    aspect-ratio: 1/1;
    margin-top: -30px;
    border-radius: 100%;
    scale: 1.2;
    transition: 0.5s;
    border: 4px solid #423C4A;
}

.slideMenu {
    width: 100%;
    background-color: #423C4A;
    position: fixed;
    bottom: -400px;
    z-index: 999999;
    pointer-events: none;
    transition: 0.5s;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.slideMenuHeader {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    height: 50px;
    align-items: center;
    color: white;
    font-size: 16pt;
}

.menu-item-list {
    padding-left: 20px;
}

.menu-item {
    padding-bottom: 20px;
    font-size: 24pt;
    color: white;
    font-family: "Audiowide";
    display: flex;
    align-items: center;
}

.menu-item > i {
    font-size: 10pt;
}

.menu-item-capital {
    color: red;
    font-family: "Audiowide";
    text-decoration: none;
    margin-left: 10px;
}