.section-body {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.section-body-banner { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 0px 10px;
}

.section-body-banner > img {
    width: 100%;
    max-width: 1220px;
    border-radius: 25px;
    padding: 10px 10px 0px 10px;
    border: 1px solid #e3e3e3;
}

.section-models-scroll {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

.section-models-scroll::-webkit-scrollbar {
    display: none;
}

.section-models {
    display: flex;
    height: 100%;
    padding-top: 10px;
}

@media(max-width: 1200px) {
    .section-models {
        padding-left: calc((100vw - 1220px) / 2);
    }

    .section-model-body { 
        margin-left: 10px;
    }
}

@media(min-width: 1200px) {
    .section-models {
        display: grid;
        grid-template-columns: repeat(auto-fill, 120px);
        gap: 10px;
        justify-content: center;
        max-width: 1220px;
    }
    .section-models-scroll {
        justify-content: center;
    }

    .section-model-body {
        margin-left: 0px;
    }
}

.section-content-body {
    width: 100%;
    display: flex;
    justify-content: center;
}

.section-content {
    display: flex;
    width: 100%;
    max-width: 1220px;
    flex-wrap: wrap;
    padding: 5px;
}

.section-model-body {
    width: 120px;
    background-color: #f1f1f1;
    display: flex;
    border-radius: 25px;
    border: 1px solid #e3e3e3;
    flex-direction: column;
    padding: 10px;
    color: black;
    text-decoration: none;
}

.section-model-body-inv {
    background-color: white;
}

.section-model-body > img {
    width: 100%;
}

.section-model-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    text-align: center;
}

.go-to-section-button-holder {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-top: 10px;
}

.go-to-section-button {
    background-color: #e11134;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    margin-left: 10px;
    opacity: 0.9;
    transition: scale 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    @media(hover: hover) {
        &:hover {
            opacity: 1.0;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
}

@media(max-width: 600px) {
    .section-title {
        padding-left: 10px;
    }
}

@media(min-width: 600px) {
    .section-title {
        padding-left: calc((100vw - 1200px) / 2);
    }
}