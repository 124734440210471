.cart-view-body {
    display: flex;
    width: 100%;
    justify-content: center;
}

.cart-view-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px;
    justify-content: space-around;
    @media(max-width: 800px) {
        flex-direction: column;
    }
}

.cart-view-first-side {
    width: 40%;
    @media(max-width: 800px) {
        width: 100%;
    }
}

.cart-view-second-side {
    width: 30%;
    @media(max-width: 800px) {
        width: 100%;
    }
}

.cart-item {
    display: flex;
    padding: 10px;
    border: 1px solid #d0d7de;
    margin-top: 10px;
    border-radius: 15px;
    background-color: white;
}

.cart-item > img {
    width: 100px;
    height: 100px;
}

.cart-item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cart-item-bonus {
    margin-left: 10px;
}

.cart-item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
}

.cart-item-price { 
    display: flex;
    align-items: center;
}

.empty-cart-view {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-cart-view-image {
    width: 200px;
    height: 200px;
}

.empty-cart-view-title {
    font-size: 16pt;
    margin-top: 20px;
}

.empty-cart-view-text {
    opacity: 0.5;
    padding-bottom: 20px;
}

.cart-item-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.counter {
    display: flex;
    align-items: center;
}

.counter-number {
    padding: 0px 10px;
}

.payment-method-selector {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.payment-method-selector .button-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Adjust spacing between buttons */
}

.transfer-warning {
    font-size: 10pt;
    opacity: 0.5;
    margin-top: 10px;
}

.credit-section-body {
    width: 100%;
    background-color: red;
    justify-content: center;
}

.item-view-section-container {
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #d0d7de;
    margin-top: 10px;
}

.credit-payment-options {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 25px;
}

.credit-payment-title {
    padding-bottom: 10px;
    font-weight: bold;
}