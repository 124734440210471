.categories-body {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #423C4A;
    padding-bottom: 25px;
    padding-top: 10px;
    padding-left: calc((100vw - 1180px) / 2);

}

.categories-body::-webkit-scrollbar {
    display: none;
}

.categories-content {
    display: flex;
}

.category {
    width: 120px;
    background-color: #f1f1f1;
    margin-left: 10px;
    display: flex;
    border-radius: 25px;
    border: 1px solid #e3e3e3;
    flex-direction: column;
    padding: 10px;
    text-decoration: none;
    color: black;
}

.section-model-body > img {
    width: 100%;
}

.category-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}