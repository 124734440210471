.header-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #423C4A;
    height: 50px;
    @media(max-width: 600px) {
        height: 100px;
    }
}

.header-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    @media(min-width: 600px) {
        justify-content: space-between;
    };
    @media(max-width: 600px) {
        flex-direction: column;
        height: inherit
    }
}

.header-desktop-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    @media(max-width: 600px) {
        display: none;
    }
}

.header-mobile-search {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 5px 10px 50px 10px;
    align-items: center;
    flex-direction: column;
    z-index: 999999;
}

@media(min-width: 600px) {
    .header-mobile-search {
        display: none;
    }
}

.header-desktop-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media(max-width: 600px) {
    .header-desktop-search {
        display: none;
    }
}

.desktop-search-results {
    display: flex;
    position: absolute;
    width: 65%;
    margin-top: 50px;
    background-color: white;
    border-radius: 20px;
}

.header-logo {
    font-family: "Audiowide";
    color: white;
}

.header-logo-capital {
    color: #e11134;
    font-family: "Audiowide";
    text-decoration: none;
}

.header-corners {
    display:  flex;
    justify-content: space-between;
    position: sticky;
    top: 40;
    pointer-events: none;
}

.header-corner-left {
    width: 50px;
    height: 50px;
    border-top-left-radius: 25px;
    z-index: 99;
    box-shadow: 0px -25px 0px 0px #423C4A;
}

.header-corner-right {
    width: 50px;
    height: 50px;
    border-top-right-radius: 25px;
    z-index: 99;
    box-shadow: 0px -25px 0px 0px #423C4A;
}

.after-header {
    width: 100%;
    height: 10px;
    margin-top: -60px;
}

.mobile-search-results {
    display: flex;
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    margin-top: 10px;
}