.item-body-outer {
    padding: 5px;
    width: 25%;
}

@media(max-width: 600px) {
    .item-body-outer {
        width: 50%;
    }
}

@media(min-width: 600px) {
    .item-body-outer {
        width: 25%;
    }
}

.item-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    justify-content: space-between;
    text-decoration: none;
    color: black;
}

.item-body-inner {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.item-body > img {
    width: 100%;
}

.item-title {
    padding: 0px 10px;
    padding-top: 10px;
    line-height: 20px;
    height: 70px;
    overflow: hidden;
}

.item-info {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-availability {
    font-size: 8pt;
    padding-left: 10px;
}

.item-available {
    color: green
}

.item-unavailable {
    color: red;
}

.image-preview {
    display: flex;
    width: 100%;
    aspect-ratio: 1/1;
}

.image-preview > img {
    width: 100%;
    height: 100%;
    padding: 5px;
    object-fit: contain;
}

.item-delivery {
    color: darkorange;
}

.item-price {
    font-size: 10pt;
}

.item-show-button {
    background-color: #e11134;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    opacity: 0.9;
    transition: scale 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(hover: hover) {
        &:hover {
            opacity: 1.0;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
}